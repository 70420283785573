<template>
  <div>
    <v-row class="mt-3" no-gutters justify="space-between" align="center">
      <div v-if="$vuetify.breakpoint.lgAndUp">
        <div class="d-flex align-center flex-wrap">
          <div class="mb-n7 px-3">
            <DatePicker
              v-if="checkPrivilages('AAS04')"
              :rule="[]"
              :label="$t('bk.date')"
              :value="modelDate"
              :maxDate="currentDate"
              outlined
              dense
              @getValue="v => (modelDate = v)"
            />
          </div>
          <v-combobox
            v-if="checkPrivilages('AAS05')"
            v-model="modelClass"
            :items="dataClassList"
            :label="$t('master_data.student.tab1_data.choose_class')"
            :item-text="concatString"
            dense
            item-value="id"
            outlined
            hide-details
            class="body-2 mr-2 select-150"
            @change="changeClass"
          />
          <!-- <v-select
            v-if="checkPrivilages('AAS05')"
            v-model="modelClass"
            :items="dataClassList"
            :label="$t('master_data.student.tab1_data.choose_class')"
            :item-text="item => `${item.grade} / ${item.name}`"
            item-value="id"
            hide-details
            outlined
            dense
            class="body-2 mr-2 select-150"
            @change="changeClass"
          /> -->
          <v-select
            v-if="checkPrivilages('AAS06')"
            :items="absentType"
            :disabled="!modelClass"
            v-model="modelAbsent"
            item-value="id"
            item-text="name"
            hide-details
            outlined
            dense
            class="body-2 mr-2 select-150"
            @change="changeAbsent"
          />
          <div class="subtitle-2 font-weight-bold">
            {{ table.total }} {{ $t("master_data.student.student") }}
          </div>
        </div>
      </div>
      <div v-else class="d-flex align-center">
        <v-btn color="primary" @click="bottomMenu = !bottomMenu" depressed>
          <v-icon>mdi-filter-outline</v-icon>
        </v-btn>
        <div class="subtitle-2 font-weight-bold ml-2">
          {{ table.total }} {{ $t("master_data.student.student") }}
        </div>
      </div>
      <v-spacer />
      <v-text-field
        v-if="checkPrivilages('AAS07')"
        v-model="search"
        :label="$t('app.search')"
        :error="!searchValid"
        :hide-details="searchValid"
        :error-messages="searchValid ? null : $t('absent.search_valid_rules')"
        :class="$vuetify.breakpoint.xsOnly && 'mt-2'"
        outlined
        dense
        clearable
        prepend-inner-icon="mdi-magnify"
        style="max-width: 250px"
        @keydown="searchHandler()"
        @click:clear="searchHandler()"
      />
    </v-row>

    <v-bottom-sheet v-model="bottomMenu">
      <v-sheet class="text-center pa-3">
        <v-row justify="center">
          <v-col cols="12" sm="4">
            <DatePicker
              v-if="checkPrivilages('AAS04')"
              :rule="[]"
              :label="$t('bk.date')"
              :value="modelDate"
              :maxDate="currentDate"
              outlined
              dense
              @getValue="v => (modelDate = v)"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-combobox
              v-if="checkPrivilages('AAS05')"
              v-model="modelClass"
              :items="dataClassList"
              :label="$t('master_data.student.tab1_data.choose_class')"
              :item-text="concatString"
              dense
              item-value="id"
              outlined
              @change="changeClass"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-select
              v-if="checkPrivilages('AAS06')"
              :items="absentType"
              :disabled="!modelClass"
              v-model="modelAbsent"
              item-value="id"
              item-text="name"
              outlined
              dense
              @change="changeAbsent"
            />
          </v-col>
        </v-row>
      </v-sheet>
    </v-bottom-sheet>

    <v-row class="my-3">
      <v-col cols="12">
        <div v-if="multipleId.length > 0" class="d-flex justify-end">
          <!-- v-if="
              multipleId.some(d =>
                modelAbsent == 4 ? d.real_in == null : d.real_out == null
              )
            " -->
          <v-btn
            v-if="showButtonAdd"
            class="gradient-primary mx-2 caption"
            small
            outlined
            dark
            @click="showDialogAdded()"
          >
            <v-icon size="20" class="pr-1">mdi-plus-circle-outline</v-icon>
            {{ $t("absent.added_absent") }}
          </v-btn>
          <v-btn
            v-if="showButtonDelete"
            class="gradient-error mx-2 caption"
            small
            outlined
            dark
            @click="isDialogDelete()"
          >
            <v-icon size="20" class="pr-1">mdi-delete-forever</v-icon>
            {{ $t("absent.remove_absent") }}
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-data-table
      v-if="checkPrivilages('AAS08')"
      v-model="multipleId"
      :headers="tableHeaders"
      :items="dataStudent"
      :page.sync="queryData.page"
      :items-per-page="queryData.limit"
      :loading="loading"
      disable-pagination
      item-key="person"
      hide-default-footer
      show-select
      class="elevation-0"
    >
      <!-- <template #header.data-table-select /> -->
      <template #item.data-table-select="{ item, isSelected, select }">
        <v-icon
          v-if="
            item.type_in === 2 || item.type_in === 6
              ? true
              : multipleId.length != 0
              ? multipleId.some(d =>
                  modelAbsent == 4
                    ? d.real_in == item.real_in
                    : d.real_out == item.real_out
                )
                ? false
                : true
              : modelAbsent == 5
              ? !item.real_in
              : false
          "
          color="grey lighten-3"
        >
          mdi-square-outline
        </v-icon>
        <v-simple-checkbox v-else :value="isSelected" @input="select($event)" />
      </template>
      <template #item.absent_type="{ item }">
        {{ getDataTable(item).absentType }}
      </template>
      <template #item.status="{ item }">
        {{ getDataTable(item).status }}
      </template>
      <template #item.hour="{ item }">
        {{ getDataTable(item).hours }}
      </template>
      <template #item.info="{ item }">
        {{ getDataTable(item).info }}
      </template>
      <template #item.action="{ item }">
        <v-row class="d-flex align-center justify-center">
          <!-- show button add -->
          <v-tooltip
            left
            v-if="getDataTable(item).hours == '-'"
            max-width="200"
          >
            <template #activator="{ on }">
              <div v-on="on">
                <v-btn
                  :class="multipleId.length == 0 && 'mx-2 primary'"
                  :dark="
                    multipleId.length == 0 ||
                    item.type_in === 2 ||
                    item.type_in === 6
                      ? true
                      : false
                  "
                  :disabled="
                    multipleId.length > 0 ||
                    item.type_in === 2 ||
                    item.type_in === 6
                      ? true
                      : false
                  "
                  icon
                  v-on="on"
                  small
                  @click="
                    modelAbsent == 5 && !item.real_in
                      ? snackbarAbsentOut()
                      : showDialogAdded(item)
                  "
                >
                  <v-icon size="20">mdi-plus-circle-outline</v-icon>
                </v-btn>
              </div>
            </template>
            <span>
              {{ $t("absent.added_manual") }}
            </span>
          </v-tooltip>
          <!-- show button delete -->
          <v-tooltip left v-else>
            <template #activator="{ on }">
              <v-btn
                :class="multipleId.length == 0 && 'mx-2 error'"
                :dark="multipleId.length == 0"
                :disabled="multipleId.length > 0"
                icon
                v-on="on"
                small
                @click="isDialogDelete(item)"
              >
                <v-icon size="20">mdi-delete-forever</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("absent.delete_manual") }}</span>
          </v-tooltip>
          <v-tooltip left>
            <template #activator="{ on }">
              <v-btn
                :style="multipleId.length == 0 && 'background: #3e4295'"
                :dark="multipleId.length == 0"
                :disabled="multipleId.length > 0"
                icon
                v-on="on"
                small
                class="mx-2"
                @click="isDialogReport(item.person)"
              >
                <v-icon size="20">mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("absent.absent_report") }}</span>
          </v-tooltip>
        </v-row>
      </template>
    </v-data-table>

    <Pagination
      :length="table.length"
      :total="table.total"
      :current-page="table.page"
      :limit="table.limit"
      :handler="paginateChange"
      class="ma-3"
    />

    <DialogAdded
      :roleName="$t('absent.student')"
      :model="modelDialogAdded"
      :idPerson="idPerson"
      :date="modelDate"
      :absentException="dataAbsentException"
      :hours="hours"
      :typeAbsent="modelAbsent"
      @isOpen="isDialogAdded"
      @getData="getData"
    />
    <DialogReport
      :model="modelDialogReport"
      :month="indexMonth"
      :year="year"
      :person="`${person}`"
      :title="$t('absent.absent_student')"
      @isOpen="isDialogReport"
    />
    <ModalConfirm
      :title="
        modelAbsent == 4
          ? $t('absent.delete_absent_in')
          : $t('absent.delete_absent_out')
      "
      :close="() => (this.confirmDelete = false)"
      :isOpen="confirmDelete"
      :save="deleteAbsences"
      :loading="loadingBtn"
    />
  </div>
</template>

<script>
import {
  getListAbsent,
  absentException,
  deleteAbsent
} from "@/api/admin/absent/absent";
import { getActiveClass } from "@/api/admin/information";
import i18n from "@/i18n";
import moment from "moment/moment";
import { checkPrivilages } from "@/utils/privilages";
import { mapGetters } from "vuex";
moment.locale("id");

let typingTimer;
const doneTypingInterval = 750;

export default {
  props: {
    init: Boolean
  },
  components: {
    DialogAdded: () => import("./DialogAdded"),
    DialogReport: () => import("./DialogReport"),
    DatePicker: () => import("@/components/DatePicker"),
    ModalConfirm: () => import("@/components/ModalConfirm"),
    Pagination: () => import("@/components/Pagination")
  },
  computed: mapGetters(["g_language"]),
  data() {
    return {
      currentDate: moment().format("YYYY-MM-DD"),
      table: {
        length: 0,
        total: 0,
        page: 1,
        limit: 10
      },
      bottomMenu: false,
      modelClass: null,
      searchValid: true,
      confirmDelete: false,
      loadingDialogConfirm: false,
      modelDate: "",
      dataStudent: [],
      loading: false,
      tableHeaders: [
        {
          text: i18n.t("app.name"),
          align: "left",
          value: "name",
          width: 180
        },
        {
          text: i18n.t("bk.table.status"),
          value: "status",
          width: 130
        },
        {
          text: i18n.t("absent.absent_type"),
          value: "absent_type",
          width: 130
        },
        {
          text: i18n.t("journal.table.information"),
          value: "info",
          width: 150
        },
        {
          text: i18n.t("absent.hour_of_entry"),
          value: "hour",
          width: 130
        },
        {
          text: i18n.t("absent.table.action"),
          sortable: false,
          value: "action",
          align: "center",
          width: 120
        }
      ],
      dataAllList: {
        sort: "class_master",
        order: "DESC",
        free: "",
        search: "",
        filter: {}
      },
      dataClassList: [],
      queryData: {
        sort: ["absence_type"],
        order: "ASC",
        search: "",
        absence_type: "4",
        filter: {
          "class_students.class": [],
          "absences.date": [moment().format("YYYY-MM-DD")]
        }
      },
      dataEdit: {
        name: "",
        nis: "",
        oldUID: "",
        idPerson: ""
      },
      modelDialogAdded: false,
      modelDialogReport: false,
      multipleId: [],
      absentType: [
        { id: 4, name: i18n.t("absent.hour_of_entry") },
        { id: 5, name: i18n.t("absent.hour_out") }
      ],
      // 4 == absen masuk, 5 == absen pulang
      modelAbsent: 4,
      idPerson: [],
      idAbsent: [],
      dataAbsentException: {},
      // 1 == absen masuk, 2 == absen pulang
      bodyAbsentException: {
        flag: "1",
        class: "",
        date_of_month: moment().format("YYYY-MM-DD")
      },
      hours: "",
      search: "",
      indexMonth: "",
      person: "",
      loadingBtn: false,
      year: "",
      masterAbsent: [],
      showButtonAdd: false,
      showButtonDelete: false
    };
  },
  watch: {
    init: {
      immediate: true,
      handler() {
        this.getData();
      }
    },
    modelDate(value) {
      this.queryData.filter["absences.date"] = [value];
      this.getData();
    },
    multipleId(value) {
      const hours = value.map(item => this.getDataTable(item).hours);

      if (hours.includes("-")) this.showButtonAdd = true;
      else this.showButtonAdd = false;

      if (hours.some(item => item !== "-")) this.showButtonDelete = true;
      else this.showButtonDelete = false;
    }
  },
  async created() {
    this.dataClassList = await getActiveClass().then(r => r.data.data);
    this.masterAbsent = await absentException().then(res => res.data.data);
  },
  mounted() {
    this.modelDate = moment().format("YYYY-MM-DD");
  },
  methods: {
    paginateChange(page, limit) {
      this.table.page = page;
      this.table.limit = limit;
      this.getData();
    },
    searchHandler() {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        if (this.modelClass == null) {
          this.searchValid = false;
        } else {
          this.table.page = 1;
          this.searchValid = true;
          this.queryData.search = this.search;
          this.getData();
        }
      }, doneTypingInterval);
    },
    checkPrivilages: name => checkPrivilages(name),
    getAbsentType(type) {
      let result = "-";
      switch (type) {
        case 0:
          result = "-";
          break;
        case 4:
          result = this.$i18n.t("absent.manual_absent");
          break;
        case 5:
          result = this.$i18n.t("absent.uid_absent");
          break;
        default:
          result = "-";
          break;
      }
      return result;
    },
    getDataTable(data) {
      let result;
      const realIn = data.real_in;
      const realOut = data.real_out;
      // handle type_in and type_out null
      const typeIn = this.masterAbsent.some(item => item.type === data.type_in)
        ? data.type_in
        : 0;
      const typeOut = this.masterAbsent.some(
        item => item.type === data.type_out
      )
        ? data.type_in
        : 0;
      const statusIn = this.masterAbsent.find(d => d.type == typeIn);
      const statusOut = this.masterAbsent.find(d => d.type == typeOut);
      switch (this.modelAbsent) {
        case 4:
          result = {
            status: this.g_language == "en" ? statusIn.name_eng : statusIn.name,
            hours: realIn?.split(" ")[1].substr(0, 5) ?? "-",
            info: data.desc_in ?? "-",
            absentType: this.getAbsentType(data.type_in)
          };
          break;
        case 5:
          result = {
            status:
              this.g_language == "en" ? statusOut.name_eng : statusOut.name,
            hours: realOut?.split(" ")[1].substr(0, 5) ?? "-",
            info: data.desc_out ?? "-",
            absentType: this.getAbsentType(data.type_out)
          };
          break;
        default:
          result = data.real_in;
          break;
      }
      return result;
    },
    changeClass(item) {
      this.searchValid = true;
      this.queryData.filter["class_students.class"] = [`${item.id}`];
      this.bodyAbsentException.class = `${item.id}`;
      this.getData();
    },
    changeAbsent(id) {
      let flagAbsentException = "";
      switch (id) {
        case 4:
          // 1 == absen masuk
          flagAbsentException = "1";
          break;
        case 5:
          // 2 == absen pulang
          flagAbsentException = "2";
          break;
      }
      this.tableHeaders[4].text = this.absentType.find(d => d.id == id).name;
      this.queryData.absence_type = [`${id}`];
      // retrive absent exception data for manual absent
      this.bodyAbsentException = { flag: `${flagAbsentException}` };
      this.bodyAbsentException.class = `${this.modelClass.id}`;
      // translate header table
      this.getData();
    },
    concatString: item => item.grade + " / " + item.name,
    getData() {
      this.multipleId = [];
      this.dataStudent = [];
      if (this.modelClass) {
        this.loading = true;
        const body = {
          ...this.queryData,
          limit: this.table.limit,
          page: this.table.page
        };
        getListAbsent(body, true)
          .then(res => {
            const r = res.data.data;
            this.table.total = r.total;
            this.table.length = r.last_page;
            this.dataStudent = r.data;
          })
          .finally(() => (this.loading = false));
      }
      if (this.modelClass != null) {
        this.loading = true;
        this.hours = "";
        absentException(this.bodyAbsentException)
          .then(res => {
            let r = res.data;
            const hours = r.student_hours;
            this.dataAbsentException = r.data.find(
              d => d.type != this.modelAbsent
            );
            if (hours) {
              switch (this.modelAbsent) {
                case 4:
                  this.hours = hours.hour_in ? hours.hour_in.substr(0, 5) : "";
                  break;
                case 5:
                  this.hours = hours.hour_out
                    ? hours.hour_out.substr(0, 5)
                    : "";
                  break;
              }
            }
          })
          .finally(() => (this.loading = false));
      }
    },
    deleteAbsences() {
      this.loadingBtn = true;
      deleteAbsent({
        id: this.idAbsent,
        flag: this.modelAbsent === 4 ? "in" : "out"
      })
        .then(res => {
          if (res.data.code) {
            this.$store.commit("CALL_SNACKBAR", {
              msg: this.$i18n.t("absent.response.success_delete"),
              color: "success"
            });
            this.getData();
            this.loadingBtn = false;
            this.confirmDelete = false;
          } else {
            this.$store.commit("CALL_SNACKBAR", {
              msg: res.data.message,
              color: "error"
            });
          }
        })
        .finally(() => (this.loadingBtn = false));
    },
    snackbarAbsentOut() {
      this.$store.commit("CALL_SNACKBAR", {
        msg: this.$i18n.t("absent.please_add_absent_present"),
        color: "error"
      });
    },
    isDialogAdded() {
      this.$nextTick(() => {
        this.modelDialogAdded = !this.modelDialogAdded;
      });
    },
    isDialogReport(person) {
      this.indexMonth = moment(this.modelDate).format("M");
      this.year = moment(this.modelDate).format("Y");
      this.person = person;
      this.modelDialogReport = !this.modelDialogReport;
    },
    isDialogDelete(data) {
      this.confirmDelete = true;
      this.idAbsent = [];
      if (data != null) {
        this.idAbsent.push(data.id);
      } else {
        this.idAbsent = this.multipleId
          .filter(item => this.getDataTable(item).hours !== "-")
          .map(item => item.id);
      }
    },
    showDialogAdded(data) {
      this.idPerson = [];
      if (data != null) {
        this.idPerson.push(data.person);
      } else {
        this.idPerson = this.multipleId
          .filter(item => this.getDataTable(item).hours === "-")
          .map(item => item.person);
      }
      this.modelDialogAdded = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.v-select__slot {
  .v-label {
    font-size: 14px;
  }
}
</style>
